import * as React from 'react';
import { SVGProps } from 'react';
const CloseDark = (props: SVGProps<SVGSVGElement>) => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="CloseDark" data-sentry-source-file="CloseDark.tsx">
		<mask id="mask0_8_75" style={{
    maskType: 'alpha'
  }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16" data-sentry-element="mask" data-sentry-source-file="CloseDark.tsx">
			<rect width="16" height="16" fill="#D9D9D9" data-sentry-element="rect" data-sentry-source-file="CloseDark.tsx" />
		</mask>
		<g mask="url(#mask0_8_75)" data-sentry-element="g" data-sentry-source-file="CloseDark.tsx">
			<path d="M4.85 12L4 11.15L7.15 8L4 4.85L4.85 4L8 7.15L11.15 4L12 4.85L8.85 8L12 11.15L11.15 12L8 8.85L4.85 12Z" fill="#121118" data-sentry-element="path" data-sentry-source-file="CloseDark.tsx" />
		</g>
	</svg>;
export default CloseDark;